<template>
  <b-card-code title="Tabel Divisi">
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <b-button
          variant="outline-primary"
          class="bg-gradient-primary mt-2"
          style="position: absolute;right: 15px;top: -10px;"
          type="submit"
          @click="openModal()"
        >
          <span class="align-middle">Tambah Divisi</span>
        </b-button>

      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="dataRows"
      :is-loading="loading"
      style-class="vgt-table striped"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        position: 'bottom'
      }"
    >
      <div
        slot="emptystate"
        align="center"
      >
        Belum ada data
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'NoDisposisi'"
          class="text-nowrap"
        >
          <span
            v-b-tooltip.hover.right="'Klik untuk lihat Detail'"
            class="text-nowrap link-no"
            variant="outline-primary"
            @click="detailDisposisi(props.row.NoDisposisi)"
          >{{ props.row.NoDisposisi }}</span>
        </span>

        <!-- Column: Status -->
        <span
          v-else-if="props.column.field === 'Status'"
          v-b-modal.modal-center
          :title="props.row.Komentar"
        >
          <b-badge :variant="statusVariant(props.row.Status)">
            {{ props.row.Status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'Aksi'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none nopad"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="Edit(props.row)">
                <feather-icon
                  :icon="authorize ? 'Edit2Icon' : 'EyeIcon' "
                  class="mr-50"
                />
                <span>{{ authorize ? 'Edit' : 'Lihat' }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              {{ fromPage }} sampai {{ toPage }}
            </span>
            <span class="text-nowrap">, dari {{ totalPage }} entri </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />

          </div>

          <div>
            <template>
              <b-button
                variant="outline-primary"
                class="bg-gradient-primary mt-2"
                pill
                :disabled="!prev"
                size="sm"
                @click="prev ? getDisposisi('prev') : ''"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </b-button>
            </template>
            <template>
              <b-button
                variant="outline-primary"
                class="bg-gradient-primary mt-2 ml-1"
                pill
                :disabled="!next"
                size="sm"
                @click="next ? getDisposisi('next') : ''"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </b-button>
            </template>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal
      id="modal-primary"
      v-model="modal"
      ok-title="Simpan"
      modal-class="modal-primary"
      cancel-variant="outline-secondary"
      cancel-title="Kembali"
      centered
      size="lg"
      title="Form Pengguna"
      no-stacking
      @ok="handleOk()"
    >
      <div>
        <b-row class="match-height">
          <b-col md="6">
            <b-form-group
              label="Nama"
              label-for="Nama"
            >
              <b-form-input
                id="Nama"
                v-model="Nama"
                placeholder="Input Nama"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Deskripsi"
              label-for="Deskripsi"
            >
              <b-form-input
                id="Deskripsi"
                v-model="Deskripsi"
                placeholder="Input Deskripsi"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mt-1"
          >
            <b-form-group
              label="Group"
              label-for="Group"
            >
              <b-form-select
                v-model="Group"
                :options="optionGroup"
                placeholder="Pilih Group"
              />
            </b-form-group>
          </b-col>

        </b-row>

      </div>
    </b-modal>

  </b-card-code>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  BRow,
  BCol,
  BFormCheckbox,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '@axios'
import dayjs from 'dayjs'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    VBModal,
    VBTooltip,
    BRow,
    BFormCheckbox,
    BCol,
    DateRangePicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageTitle: '',
      loading: true,
      pageLength: 10,
      dir: false,
      totalPage: 0,
      modal: false,
      totalRow: 0,
      page: 0,
      order: '',
      fromPage: 0,
      toPage: 0,
      prev: '',
      Nama: '',
      Deskripsi: '',
      Divisi: '',
      Group: '',
      userDetail: [],
      StatusUser: '',
      optionGroup: [],
      optionRole: [],
      next: '',
      isAdmin: false,
      filterDate: {
        startDate: null,
        endDate: null,
      },
      optionStatus: [
        { value: '2', text: 'Aktif' },
        { value: '1', text: 'Tidak Aktif' },
        { value: '4', text: 'Blokir' },
      ],
      columns: [
        {
          label: 'Nama Group',
          field: 'Nama',
        },
        {
          label: 'Deskripsi',
          field: 'Deskripsi',
        },

        {
          label: 'Aksi',
          field: 'Aksi',
        },
      ],
      dataRows: [
        {
          id: '',
          NoDisposisi: '',
          Perihal: '',
          Waktu: '',
          authorize: false,
          Deadline: '',
          Pengirim: '',
          Status: '',
          Aksi: '',
          Komentar: [],
        },
      ],
      searchTerm: '',
      search: '',
      Status: [
        {
          1: 'Close',
          2: 'Open',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Procces: 'light-primary',
        Open: 'light-success',
        Close: 'light-danger',
        Resigned: 'light-warning',
        Process: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  watch: {
    $route() {
      this.getDisposisi()
    },
    pageLength() {
      this.getDisposisi()
    },
    search() {
      this.getDisposisi()
    },
  },
  created() {
    this.isAdmin = store.state.userData.data.is_admin === 1
    this.authorize = store.state.userData.data.is_admin === 1
  },
  mounted() {
    this.getDisposisi()
    this.getGroup()
  },
  methods: {
    editDisposisi(e) {
      if (this.authorize) {
        window.location.href = `edit-disposisi/${e}`
      } else {
        window.location.href = `detail-disposisi/${e}`
      }
    },
    async getGroup() {
      const { data } = await axios.get('manage/group')
      this.optionGroup = data.Response.data.data.map(e => ({
        value: e.id,
        text: e.name,
      }))
    },
    detailDisposisi(e) {
      window.location.href = `detail-disposisi/${e}`
    },
    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },
    async handleOk() {
      let url
      if (this.order === 'add') {
        url = 'manage/role/add'
      } else {
        url = `manage/role/update/${this.userDetail}`
      }
      await axios.post(url, {
        name: this.Nama,
        status: 'Active',
        group: this.Group,
        desc: this.Deskripsi,
        permissions: [],
      })
      this.getDisposisi()
    },

    async Edit(val) {
      this.userDetail = val.id
      this.Nama = val.Nama
      this.Deskripsi = val.Deskripsi
      this.Group = val.Group
      this.order = 'edit'
      this.modal = true
    },

    openModal() {
      this.Nama = ''
      this.Deskripsi = ''
      this.Group = ''
      this.order = 'add'
      this.modal = !this.modal
    },

    async getDisposisi(val) {
      const defaultUrl = '/manage/role'
      let url = ''
      if (val === 'next') {
        url = `${defaultUrl}${new URL(this.next).search}`
      } else if (val === 'prev') {
        url = `${defaultUrl}${new URL(this.prev).search}`
      } else {
        url = defaultUrl
      }
      const { data } = await axios.get(url, {
        params: {
          // admin: this.isAdmin,
          // status: this.$route.name === 'proses-disposisi' ? 'Open' : 'Close',
          // limit: this.pageLength,
        },
      })
      this.totalPage = data.data.total
      this.totalRow = data.data.per_page
      this.prev = data.data.prev_url
      this.next = data.data.next_url
      this.fromPage = data.data.from
      this.toPage = data.data.to
      if (data.data.total !== 0) {
        this.dataRows = data.data.data.map(e => ({
          id: e.id,
          Nama: e.name,
          Deskripsi: e.desc,
          Group: e.group_id,
          Aksi: '',
        }))
      } else {
        this.dataRows = []
      }
      this.loading = false
      //   .catch(error => {
      //     console.log(error)
      //   })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
.nopad {
  padding: 0;
}
.link-no {
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 3px;
  cursor: pointer;
}
</style>
